import { styled, Box } from '@mui/material';

export const ResetPasswordWrapper = styled(Box)(({ theme }) => ({
  width: '100%',

  '.wrapper': {
    background: theme.palette.neutralLight.light,
    borderRadius: 4,
    padding: '48px 48px 36px',
    maxWidth: 620,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto 80px',

    '&__field': {
      width: '100%',
    },

    '&__footer': {
      display: 'flex',
      gap: 16,
      width: '100%',
      justifyContent: 'flex-end',
    },
  },
}));
