import Loader from '@components/Loader';
import Spacer from '@components/Spacer';
import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { SecurityWrapper } from './Security.styles';
import Tickbox from '@components/Tickbox';
import { CCPDefaultButton } from '@components/index';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useCallback, useEffect, useState } from 'react';
import { SettingsActions } from '@features/settings/store';
import { FetchingStatus } from '@api/interfaces';

const Security = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const {
    data: { is2FAEnabled },
    fetchingStatus,
  } = useAppSelector(state => state.settings.security.mfa);

  const [MFAState, setMFAState] = useState(is2FAEnabled);

  const changeMFA = () => {
    setMFAState(!MFAState);
  };

  const resetAll = useCallback(async () => {
    setMFAState(is2FAEnabled);
  }, [setMFAState, is2FAEnabled]);

  const doSave = async () => {
    dispatch(SettingsActions.updateMFASetting(MFAState));
  };

  useEffect(() => {
    resetAll();
  }, [resetAll]);

  useEffect(() => {
    dispatch(SettingsActions.getMFASetting({}));
  }, [dispatch]);

  return (
    <SecurityWrapper id='securitySettingsWrapper' position='relative'>
      <Loader show={fetchingStatus === FetchingStatus.PENDING} portalId='securitySettingsWrapper' />
      <Typography variant='h6'>
        {formatMessage({
          id: 'setting.security.title',
          defaultMessage: 'Two-Factor authentication',
        })}
      </Typography>
      <Spacer size='md' />
      <Typography variant='subtitle1'>
        {formatMessage({
          id: 'setting.security.subtitle',
          defaultMessage:
            'Add an extra layer of security by prompting users to verify their identity when they login to CP.',
        })}
      </Typography>
      <Spacer size='2xl' />
      <Tickbox
        label={formatMessage({
          id: 'setting.security.account_settings.mfa.tickbox',
          defaultMessage: 'Enable Two-Factor Authentication (2FA)',
        })}
        value={MFAState}
        fieldName='autoConfirmSignup'
        handleChange={changeMFA}
        description={formatMessage({
          id: 'setting.security.mfa.subtitle',
          defaultMessage:
            'Security by requiring users to provide two forms of identification: their password and a second factor (e.g., a code from an app, SMS, or biometrics).',
        })}
      />
      <Box flexGrow='1' />
      <Stack direction='row' spacing={3} justifyContent='flex-end'>
        <CCPDefaultButton variant='outlined' onClick={resetAll}>
          {formatMessage({
            id: 'user_setting.settings.reset',
            defaultMessage: 'Reset all',
          })}
        </CCPDefaultButton>
        <CCPDefaultButton variant='contained' onClick={doSave}>
          {formatMessage({
            id: 'user_setting.settings.apply',
            defaultMessage: 'Apply & save',
          })}
        </CCPDefaultButton>
      </Stack>
    </SecurityWrapper>
  );
};

export default Security;
